<template>
  <div class="login-wrap">
    <div class="header">
      <img src="/img/login/logo.png" alt="">
    </div>
    <el-card class="login-box">
      <div class="login-title">用户登录</div>
      <el-form ref="formEl" :model="loginForm" :rules="rules" label-width="auto" size="large">
        <el-form-item prop="username">
            <el-input 
              placeholder="账号" 
              v-model="loginForm.username"
              @keyup.enter.native="doLogin"
            ></el-input>
        </el-form-item>
        <el-form-item prop="password">
            <el-input 
              type="password" 
              show-password 
              placeholder="密码" 
              v-model="loginForm.password" 
              @keyup.enter.native="doLogin">
            </el-input>
        </el-form-item>
        <el-form-item prop="vcode">
          <div class="verify-box">
            <el-input 
              v-model="loginForm.vcode"
              @keyup.enter.native="doLogin"
              placeholder="验证码" 
              style="flex:1">
            </el-input>
            <img :src="codePath" alt="验证码获取失败" style="cursor:pointer" @click="refreshCode">
          </div>
        </el-form-item>
        <!-- <div class="fgt-psw" @click="forgetPassword">忘记密码</div> -->
        <div style="height: 10px;"></div>
        <el-button class="login-btn" @click="doLogin">登 录</el-button>
      </el-form>
    </el-card>
    <div class="footer">©版权所有：北京北大软件工程股份有限公司</div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { encryptedData } from '@/views/Login/encryptUtil'
import { fzzykRequest } from '@/service'
import { useRoute, useRouter } from 'vue-router'
localStorage.removeItem('loginInfo')
sessionStorage.removeItem('loginInfo')
fzzykRequest.get({ url: 'logout' })
const router = useRouter()
const route = useRoute()
const formEl = ref()
const loginForm = ref({
  username: '',
  password: '',
  vcode: ''
})
const rules = ref({
  username:[{required: true, message: '请输入账号', trigger: 'blur'}],
  password:[{required: true, message: '请输入密码', trigger: 'blur'}],
  vcode:[{required: true, message: '请输入验证码', trigger: 'blur'}],
})
// 刷新验证码
const codePath = ref('')
const verifyCodeKey = ref('123')
refreshCode()
function refreshCode() {
  loginForm.value.vcode = ''
  fzzykRequest.get({
    url: 'refreshCaptcha', 
    params: {
      verifyCodeKey: verifyCodeKey.value
    }
  }).then(res=>{
    const { captchaKey, base64Img } = res.data
    verifyCodeKey.value = captchaKey
    codePath.value = base64Img
  })
}
// 登录
const doLogin = () => {
  formEl.value.validate((flag)=>{
    if(!flag) return
    fzzykRequest.post({
      url: 'systemLogin', params: {
        userName: loginForm.value.username,
        passWord: encryptedData(loginForm.value.password),
        verifyCode: loginForm.value.vcode,
        verifyCodeKey: verifyCodeKey.value
      }
    }).then((res) => {
      localStorage.setItem('loginInfo', JSON.stringify(res.data))
      if (route.query.redirect) {
        const redirectUrl = atob(route.query.redirect)
        const urlSplits = redirectUrl.split('?')
        const path = urlSplits[0]
        const urlParamStr = urlSplits.length > 1 ? urlSplits[1] : ''
        const urlParams = new URLSearchParams(urlParamStr)
        const params = {}
        for (const [key, value] of urlParams.entries()) {
          // 将参数键值对存储到 result 对象中
          params[key] = value
        }
        router.push({ path: path, query: params })
      } else {
        router.push('/Home')
      }
    }).catch(err=>{
      refreshCode()
    })
  })
}
// 忘记密码
const forgetPassword = () => {
  alert('功能开发中')
}
</script>

<style scoped lang="less">
.login-wrap{
  height:100vh;
  position: relative;
  font-family: PingFang SC;
  background:url(/img/login/bg.png) center / 100% 100%;
  .header{
    position: absolute;
    top: 30px;
    left: 30px;
  }
  .login-box{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 200px;
    width: 500px;
    padding: 20px 20px 40px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    .login-title{
      font-weight: 800;
      font-size: 36px;
      color: #008AFF;
      margin-bottom: 40px;
    }
    :deep(.el-input--large){
      --el-input-height:70px;
      .el-input__wrapper{
        box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.03);
        font-size: 20px;
        padding:1px 0;
        .el-input__inner{
          padding: 0 15px
        }
        .el-input__suffix{
          padding-right:8px
        }
      }
    }
    .verify-box{
      display: flex;
      width: 100%;
      img{
        height: 70px
      }
    }
    .fgt-psw{
      color: #008AFF;
      margin:10px 0 50px;
      font-size: 18px;
      cursor: pointer;
      &:hover{
        opacity: .8;
      }
    }
    .login-btn{
      width:100%;
      height: 70px;
      color: #fff;
      background: linear-gradient(88deg, #2975F4 0%, #32A8FC 100%);
      box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.03);
      border-radius: 9px;
      font-weight: bold;
      font-size: 24px;
      color: #FFFFFF;
      &:hover{
        opacity: .8;
      }
    }
  }
  .footer{
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 14px;
    color: #a8abb2;
  }
}
</style>